<template>
  <div class="home">
    <div class="section section-1" id="home">
      <b-container class="h-100">
        <div
          class="container-content"
          data-aos="animate__fadeInLeft"
          data-aos-delay="50"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="false"
        >
          <h1>Crypto is entering a new era. It's the best time for you to make a decision.</h1>
          <span> What is your strategy when trading CFD Cryptocurrency?</span>

          <b-form @submit.prevent="onRegister">
            <div class="register">
              <b-form-input placeholder="Email" type="email" v-model="email" required>
              </b-form-input>
              <b-button variant="success" type="submit" class="btn-tradenow">
                <a
                  :href="`https://system.esbfx.com/secure/register/?email=${email}`"
                  target="_blank"
                >
                  Trade Now
                </a>
              </b-button>
            </div>
            <div class="register-demo">
              <button type="submit">
                <a href="https://system.esbfx.com/secure/register" target="_blank">or try Demo</a>
              </button>
            </div>
          </b-form>
        </div>
      </b-container>
    </div>
    <div class="section section-2">
      <b-container>
        <b-row>
          <b-col cols="12" lg="7">
            <div
              class="box-list-trade"
              data-aos="animate__fadeInLeft"
              data-aos-delay="50"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="false"
            >
              <div class="tabs-list-header">
                <div
                  class="tab-item"
                  v-for="tab in listOne"
                  :key="`${tab.name}${tab.value}`"
                  @click="ActiveTabs(tab.value)"
                  :class="{ active: tabsActive == tab.value }"
                >
                  {{ tab.name }}
                </div>
              </div>
              <div class="tabs-list-body">
                <!-- <div class="thead-list" v-for="thead in theadList" :key="thead">
                  {{ thead }}
                </div> -->
                <b-table
                  striped
                  hover
                  :items="listSpread.items"
                  :fields="listSpread.fields"
                ></b-table>
              </div>
              <div class="tabs-list-warning">
                The indicator is to reference. The latest and most accurate prices are updated at
                your trading platform by ESB FX.
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="5">
            <div
              class="box-why"
              data-aos="animate__fadeInLeft"
              data-aos-delay="50"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="false"
            >
              <h2 class="title">WHY ESB FX IS THE BEST CHOICE FOR TRADERS?</h2>
              <p class="mini-title">More than 1000 tools are waiting for you to discover</p>
              <p class="detail mb-3">
                Our wide range of CFDs is being traded on FX, Commodities, Cryptocurrencies, Stocks,
                ETFs, and more. Low cost Smooth and fast action 24/7 support Don't have any option
                that could be better than this?
              </p>
              <div class="d-flex align-items-center justify-content-center">
                <!-- <a href="#" class="btn-redirect d-block"> View ESB FX's spreads all </a> -->
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-3">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="banner-box">
              <div class="bg-color"></div>
              <div class="bg-img">
                <img src="~@/assets/images/background/4.jpg" alt="" />
              </div>
              <div
                class="text"
                data-aos="animate__fadeInUp"
                data-aos-delay="50"
                data-aos-easing="ease-in-out"
                data-aos-mirror="true"
                data-aos-once="false"
              >
                <h2 class="title">Trading FX has never been so low</h2>
                <p class="detail mb-3">
                  Trade FX CFD spreads from 0.0 pips across 14 asset pairs with our ECN ACCOUNT. Get
                  access to deep liquidity, fast execution, and trade with micro-lots.
                </p>
                <div
                  class="
                    button-section
                    text-left
                    pt-2
                    d-flex
                    align-items-center
                    justify-content-start
                  "
                >
                  <a href="#" class="btn-redirect outline"> ESB FX's spreads </a>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-4">
      <b-container>
        <b-row>
          <b-col cols="12" data-aos="animate__fadeIn">
            <h2 class="title">
              WHY ESB FX IS <br />
              THE BEST CHOICE FOR TRADERS?
            </h2>
            <p class="mini-title">
              You are facing inadequacies when cooperating with your current broker. That's why
              we're here:
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" lg="4">
            <a href="#" class="features-small-item" data-aos="animate__fadeInLeft">
              <div class="icon">
                <i class="far fa-user-chart"></i>
              </div>
              <h5 class="features-title">Ideal spread</h5>
              <p>
                Enjoy spreads as close as 0.0 pips with great liquidity without any requirements.
              </p>
            </a>
          </b-col>
          <b-col cols="12" lg="4">
            <a href="#" class="features-small-item" data-aos="animate__fadeInUp">
              <div class="icon">
                <i class="far fa-tachometer-alt-fast"></i>
              </div>
              <h5 class="features-title">Convenience and Reputation</h5>
              <p>
                Fast operation with 99.9% occupancy rate and absolutely no intervention of the
                dealing desk intervention (DD).
              </p>
            </a>
          </b-col>
          <b-col cols="12" lg="4">
            <a href="#" class="features-small-item" data-aos="animate__fadeInRight">
              <div class="icon">
                <i class="fad fa-lock"></i>
              </div>
              <h5 class="features-title">Manage the broker strictly</h5>
              <p>
                Be strictly managed by SCB. The client's assets are segregated in tier 1 level
                banks.
              </p>
            </a>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-5">
      <b-container>
        <b-row>
          <b-col cols="12">
            <h2 class="title">GET HIGH ACHIEVEMENTS IN CUSTOMER SUPPORT SERVICE</h2>
            <p class="mini-title">
              In ESB FX, nothing can delay your trading. 24/5 support, diverse transaction
              resources, and smooth operation millisecond. All you have to do is focus on the basic
              factors of trading.
            </p>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col cols="12" lg="4">
            <div class="achievment-box" data-aos="animate__zoomIn">
              <div class="top-box">
                <div class="icon">
                  <img src="~@/assets/images/icons/Chat.png" alt="" />
                </div>
                <h2>Multi-language system</h2>
                <p>Multilingual support team 24/5 through live form, email, and hotline.</p>
              </div>
              <div class="bottom-box" data-aos="animate__fadeInUp">
                <a href="/contact">
                  <span> Contact</span>
                </a>
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="4">
            <div class="achievment-box" data-aos="animate__zoomIn">
              <div class="top-box">
                <div class="icon">
                  <img src="~@/assets/images/icons/apply.png" alt="" />
                </div>
                <h2>Quick registration</h2>
                <p>
                  Create an account quickly and simply securely with electronic ID verification in
                  many countries.
                </p>
              </div>
              <div class="bottom-box" data-aos="animate__fadeInUp">
                <a href="https://system.esbfx.com/secure/register" target="_blank">
                  <span> Create a trading account </span>
                </a>
              </div>
            </div>
          </b-col>
          <b-col cols="12" lg="4">
            <div class="achievment-box" data-aos="animate__zoomIn">
              <div class="top-box">
                <div class="icon">
                  <img src="~@/assets/images/icons/exchange.png" alt="" />
                </div>
                <h2>Smooth transaction with each click</h2>
                <p>The diverse system of deposit and withdrawal options.</p>
              </div>
              <div class="bottom-box" data-aos="animate__fadeInUp">
                <a href="">
                  <span> ESB FX Margin Options </span>
                </a>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="section section-6">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="banner-box">
              <div class="bg-color"></div>
              <div class="bg-img">
                <img src="~@/assets/images/background/5.jpg" alt="" />
              </div>
              <div class="text">
                <h2 class="title">It's the best time to start!</h2>
                <p class="detail mb-3">
                  Fast - simple - low start-up costs. Sign up with just one click
                </p>
                <div class="button-section text-left pt-2">
                  <a
                    href="https://system.esbfx.com/secure/register"
                    target="_blank"
                    class="button btn-register"
                    >Create a trading account
                  </a>
                  <a
                    href="https://system.esbfx.com/secure/register"
                    target="_blank"
                    class="button btn-demo"
                  >
                    <span>Demo account for trial </span>
                  </a>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal id="notification" title="" hide-footer body-class="p-0" header-class="p-0">
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        style="text-shadow: 1px 1px 2px #333"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <!-- Text slides with image -->
        <b-carousel-slide
          v-for="notifi in NotifileList"
          :key="notifi.image"
          class="img-carousel"
          :img-src="notifi.image"
        ></b-carousel-slide>
      </b-carousel>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      email: '',
      widthRs: 0,
      tabsActive: 2,
      slide: 0,
    };
  },
  computed: {
    ...mapGetters({
      NotifileList: 'core/NotifileList',
    }),
    listOne() {
      return [
        // { name: 'Popular', value: 1 },
        { name: 'Forex', value: 2 },
        { name: 'Indices', value: 3 },
        { name: 'Commodities', value: 4 },
        { name: 'Cryptocurrencies', value: 5 },
        { name: 'Shares', value: 6 },
      ];
    },
    listSpread() {
      // forex
      const Forex = [
        {
          currency: 'EURUSD',
          razor_minimum: '0',
          razor_average: '0.09',
          standard_minimum: '0.6',
          standard_average: '0.69',
        },
        {
          currency: 'AUDUSD',
          razor_minimum: '0',
          razor_average: '0.17',
          standard_minimum: '0.6',
          standard_average: '0.77',
        },
        {
          currency: 'GBPUSD',
          razor_minimum: '0',
          razor_average: '0.28',
          standard_minimum: '0.6',
          standard_average: '0.88',
        },
        {
          currency: 'USDCAD',
          razor_minimum: '0',
          razor_average: '0.35',
          standard_minimum: '0.6',
          standard_average: '0.95',
        },
        {
          currency: 'USDCHF',
          razor_minimum: '0',
          razor_average: '0.32',
          standard_minimum: '0.6',
          standard_average: '0.92',
        },
        {
          currency: 'USDJPY',
          razor_minimum: '0',
          razor_average: '0.14',
          standard_minimum: '0.6',
          standard_average: '0.74',
        },
        {
          currency: 'XAUUSD',
          razor_minimum: '0',
          razor_average: '0.32',
          standard_minimum: '1.6',
          standard_average: '2.0',
        },
        {
          currency: 'XAGUSD',
          razor_minimum: '0',
          razor_average: '0.14',
          standard_minimum: '1.8',
          standard_average: '2.0',
        },
      ];
      const fieldsForex = [
        {
          key: 'currency',
          label: 'Currency pair',
          class: 'text-left text-middle',
        },
        // {
        //   key: 'razor_minimum',
        //   label: 'Razor minimum spread',
        //   class: 'text-center text-middle',
        // },
        // {
        //   key: 'razor_average',
        //   label: 'Razor average spread*',
        //   class: 'text-center text-middle',
        // },
        {
          key: 'standard_minimum',
          label: 'Standard minimum spread',
          class: 'text-center text-middle',
        },
        {
          key: 'standard_average',
          label: 'Standard average spread*',
          class: 'text-center text-middle',
        },
      ];
      // Indices
      const Indices = [
        {
          Index: 'US 500 Index',
          Symbol: 'US500',
          Minimum: '0.4',
        },
        {
          Index: 'US Wall Street 30 Index',
          Symbol: 'US30',
          Minimum: '2.4',
        },
        {
          Index: 'US Tech 100 Index',
          Symbol: 'NAS100',
          Minimum: '1.0',
        },
        {
          Index: 'Germany 40 Index',
          Symbol: 'GER40',
          Minimum: '0.9',
        },
        {
          Index: 'UK 100 Index',
          Symbol: 'UK100',
          Minimum: '1.0',
        },
      ];
      const fieldsIndices = [
        {
          key: 'Index',
          label: 'Index',
          class: 'text-left text-middle',
        },
        {
          key: 'Symbol',
          label: 'Symbol',
          class: 'text-center text-middle',
        },
        {
          key: 'Minimum',
          label: 'Minimum spread',
          class: 'text-center text-middle',
        },
      ];
      // Commodities
      const Commodities = [
        {
          Instrument: 'Spot Gold ($)',
          Minimum: '0.05',
          Average: '0.13',
        },
        {
          Instrument: 'Spot Silver ($)',
          Minimum: '0.5',
          Average: '1.09',
        },
        {
          Instrument: 'Spot Crude',
          Minimum: '2',
          Average: '2.3',
        },
        {
          Instrument: 'Spot Brent',
          Minimum: '2',
          Average: '2.3',
        },
        {
          Instrument: 'Natural Gas',
          Minimum: '0.5',
          Average: '1.46',
        },
      ];
      const fieldsCommodities = [
        {
          key: 'Instrument',
          label: 'Instrument',
          class: 'text-left text-middle',
        },
        {
          key: 'Minimum',
          label: 'Minimum spread',
          class: 'text-center text-middle',
        },
        {
          key: 'Average',
          label: 'Average spread*',
          class: 'text-center text-middle',
        },
      ];
      // Cryptocurrencies
      const Currency = [
        {
          Instrument: 'Bitcoin',
          Minimum: '30',
          Average: '31.39',
        },
        {
          Instrument: 'Ethereum',
          Minimum: '5',
          Average: '5.5',
        },
        {
          Instrument: 'Ripple',
          Minimum: '0.023',
          Average: '0.025',
        },
        {
          Instrument: 'Polkadot',
          Minimum: '0.5',
          Average: '0.5',
        },
        {
          Instrument: 'Crypto10',
          Minimum: '90',
          Average: '90',
        },
      ];
      const fieldsCurrency = [
        {
          key: 'Instrument',
          label: 'Instrument',
          class: 'text-left text-middle',
        },
        {
          key: 'Minimum',
          label: 'Minimum spread',
          class: 'text-center text-middle',
        },
        {
          key: 'Average',
          label: 'Average spread*',
          class: 'text-center text-middle',
        },
      ];
      // Shares
      const Shares = [
        {
          Instrument: 'Apple',
          CFDs: '0 + market spread',
        },
        {
          Instrument: 'Facebook',
          CFDs: '0 + market spread',
        },
        {
          Instrument: 'Tesla',
          CFDs: '0 + market spread',
        },
        {
          Instrument: 'Uber',
          CFDs: '0 + market spread',
        },
        {
          Instrument: 'Amazon',
          CFDs: '0 + market spread',
        },
      ];
      const fieldsShares = [
        {
          key: 'Instrument',
          label: 'Currency pair',
          class: 'text-left text-middle',
        },
        {
          key: 'CFDs',
          label: 'CFDs Minimum',
          class: 'text-center text-middle',
        },
      ];
      // return
      switch (this.tabsActive) {
        case 2:
          return {
            items: Forex,
            fields: fieldsForex,
          };
        case 3:
          return {
            items: Indices,
            fields: fieldsIndices,
          };
        case 4:
          return {
            items: Commodities,
            fields: fieldsCommodities,
          };
        case 5:
          return {
            items: Currency,
            fields: fieldsCurrency,
          };
        case 6:
          return {
            items: Shares,
            fields: fieldsShares,
          };

        default:
          return {
            items: Forex,
            fields: fieldsForex,
          };
      }
    },
  },
  watch: {
    NotifileList: {
      handler(newVal) {
        if (newVal.length > 0) {
          this.$bvModal.show('notification');
        }
      },
    },
  },
  methods: {
    onRegister() {
      console.log(this.email);
    },
    handleResize() {
      this.widthRs = window.innerWidth;
    },
    ActiveTabs(value) {
      this.tabsActive = value;
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
  async mounted() {
    this.widthRs = window.innerWidth;
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss">
.home {
  .section-1 {
    overflow: hidden;
    width: 100%;
    min-height: calc(85vh - 320px);
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url('~@/assets/images/background/1.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 160px 0;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.8);
    color: #fff;
    .container-content {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      h1 {
        text-align: center;
        font-size: clamp(1.5rem, 3vw, 2rem);
        text-transform: capitalize;
        font-weight: 600;
      }
      span {
        padding-top: 10px;
        font-weight: 500;
        font-style: italic;
        text-align: center;
        font-size: clamp(1.1rem, 3vw, 1.7rem);
        text-transform: capitalize;
      }
      .register {
        margin-top: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        input {
          font-feature-settings: 'tnum';
          background-color: #fff;
          background-image: none;
          border: 1px solid #d9d9d9;
          box-sizing: border-box;
          color: rgba(0, 0, 0, 0.85);
          display: inline-block;
          font-variant: tabular-nums;
          line-height: 1.5715;
          list-style: none;
          margin: 0;
          min-width: 0;
          position: relative;
          transition: all 0.3s;
          width: 100%;
          border-radius: 50px;
          font-size: 16px;
          line-height: 28px;
          padding: 25px 200px 25px 30px;
          height: 70px;
          font-weight: 700;
        }
        button {
          width: 190px;
          height: 60px;
          position: absolute;
          line-height: 1.5em;
          padding: 17px 40px;
          border-radius: 50px;
          right: 5px;
          font-weight: 700;
          font-size: clamp(0.9rem, 3vw, 1.1rem);
          a {
            color: #fff;
          }
        }
        @media (min-width: 576px) {
          min-width: 525px;
        }
        @media (min-width: 768px) {
          min-width: 576px;
        }
        @media (min-width: 991px) {
          min-width: 700px;
        }
        @media (max-width: 425px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          input {
            height: 50px;
            padding: 5px 15px;
            width: 300px;
          }
          button {
            margin-top: 15px;
            position: relative;
            border: 5px solid #fff;
            height: 55px;
            padding: 0;
            min-width: 200px;
            width: 300px;
            right: 0;
          }
        }
      }
      .register-demo {
        margin-top: 20px;
        text-align: center;
        button {
          margin: auto;
          color: #fff;
          font-size: clamp(0.9rem, 3vw, 1.3rem);
          background: transparent;
          box-shadow: 0;
          border: 0;
          position: relative;
          &:after {
            content: '';
            width: 100%;
            height: 2px;
            background: #fff;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -5px;
          }
          &:hover {
            font-size: clamp(1rem, 3vw, 1.4rem);
          }
          a {
            color: #fff;
            text-decoration: none;
          }
        }
      }
    }
    @media (max-width: 1440px) {
      padding: 150px 0 80px;
      .container-content {
        h1 {
          font-size: clamp(1.3rem, 3vw, 1.6rem);
          margin-bottom: 0;
        }
        span {
          font-size: clamp(0.9rem, 3vw, 1.3rem);
        }
        .register {
          margin-top: 20px;
        }
      }
    }
  }
  .section-2 {
    z-index: 9;
    padding-top: 40px;
    padding-bottom: 40px;
    .box-list-trade {
      border-radius: 16px;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
      width: 100%;

      .tabs-list-header {
        align-self: stretch;
        display: flex;
        flex: auto;
        overflow: hidden;
        position: relative;
        transform: translate(0);
        white-space: nowrap;
        justify-content: space-between;
        padding: 10px 10px 0;
        .tab-item {
          flex: 1;
          height: 100%;
          min-height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #777;
          font-weight: 600;
          cursor: pointer;
          margin: 0px 10px;
          &:active,
          &:hover,
          &.active {
            color: #009750;
            border-bottom: 2px Solid #009750;
          }
        }
        @media (max-width: 575px) {
          display: none;
        }
      }
      .tabs-list-body {
        background: #fff;
        min-height: 250px;
        table {
          margin-bottom: 0;
        }
      }
      .tabs-list-warning {
        background-color: #f6f7f8;
        border-top: 1px solid #f0f0f0;
        padding: 16px 24px;
        text-align: center;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
    .box-why {
      .mini-title {
        font-size: 1.125rem;
        font-weight: 600;
        text-transform: capitalize;
        text-align: justify;
        line-height: 120%;
      }
      h2.title {
        font-size: clamp(1.2rem, 3vw, 1.8rem);
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 15px;
      }
      .detail {
        font-size: 1.125rem;
        margin-bottom: 0;
        font-weight: 400;
        text-align: justify;
      }
      @media (min-width: 1600px) {
        padding-left: 25px;
      }
      @media screen and (min-width: 1200px) and (max-width: 1599px) {
        padding-left: 80px;
      }
      @media screen and (max-width: 991px) {
        max-width: 80%;
        margin: 40px auto 10px;
      }
    }
    @media (max-width: 1440px) {
      .box-why {
        h2.title {
          font-size: clamp(1.2rem, 3vw, 1.6rem);
        }
        .mini-title {
          font-size: 1rem;
          text-align: left;
        }
      }
      padding: 25px 0;
    }
    @media (max-width: 576px) {
      .box-why {
        h2.title {
          text-align: center;
        }
        .mini-title {
          text-align: center;
          font-size: 1rem;
        }
        .detail {
          text-align: center;
          font-size: 0.8rem;
        }
      }
    }
  }
  .section-3 {
    padding: 50px 0;
    .banner-box {
      @media (min-width: 768px) {
        min-height: 468px;
        padding: 0 64px;
      }
      min-height: 375px;
      padding: 20px;
      background: #000;
      color: #fff;
      align-items: center;
      border-radius: 16px;
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
      display: flex;
      overflow: hidden;
      padding: 16px;
      position: relative;
      box-sizing: border-box;
      .bg-img {
        img {
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          height: 100%;
          max-width: 860px;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 100;
          right: 0;
        }
      }
      .bg-color {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 200;
        background: linear-gradient(95.96deg, rgb(0, 0, 0) 40%, rgba(0, 0, 0, 0) 60%);
      }
      .text {
        width: 100%;
        max-width: 425px;
        position: relative;
        z-index: 300;
        h2.title {
          font-size: clamp(1.875rem, 3vw, 2.725rem);
          font-weight: 400;
          line-height: 110%;
          margin-bottom: 24px;
          text-align: initial;
        }
        .detail {
          font-size: 1.125rem;
          text-align: initial;
          margin-bottom: 0;
        }
      }
      @media (max-width: 575px) {
        .bg-color {
          background: linear-gradient(36deg, black 40%, rgba(0, 0, 0, 0) 80%);
        }
      }
    }
    @media (max-width: 1440px) {
      padding: 10px 0 40px;
      .banner-box {
        min-height: 285px;
        max-width: 992px;
        margin: auto;
        .text {
          h2.title {
            font-size: clamp(1.2rem, 3vw, 1.6rem);
          }
          .mini-title {
            font-size: 1rem;
            text-align: left;
          }
        }
      }
    }
    @media (max-width: 576px) {
      .banner-box {
        .text {
          h2.title {
            text-align: center;
            font-size: clamp(1.7rem, 3vw, 2.525rem);
          }
          .detail {
            text-align: center;
            font-size: 0.9rem;
          }
          .button-section {
            text-align: center !important;
            margin: auto;
            justify-content: center !important;
          }
        }
      }
    }
  }
  .section-4 {
    padding: 0 0 50px;
    .mini-title,
    h2.title {
      text-align: center;
      max-width: 840px;
      width: 80%;
      margin: {
        left: auto;
        right: auto;
      }
      text-transform: capitalize;
    }
    h2.title {
      font-size: clamp(1.2rem, 3vw, 1.8rem);
      font-weight: 600;
      line-height: 120%;
      margin-bottom: 15px;
    }
    .mini-title {
      font-size: 1.125rem;
      font-weight: 600;
      text-transform: capitalize;
      text-align: justify;
      line-height: 120%;
      margin-bottom: 50px;
    }

    .features-small-item {
      display: block;
      background: #ffffff;
      box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      padding: 30px;
      text-align: center;
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
      position: relative;
      margin-bottom: 30px;
      text-decoration: none !important;
      min-height: 300px;
      .icon {
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin: auto;
        position: relative;
        margin-bottom: 30px;
        background: #dae3ef;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        svg,
        i {
          font-size: 18px;
          color: #009750;
        }
      }
      .features-title {
        color: #333;
        font-size: clamp(15px, 3vw, 20px);
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 15px;
        letter-spacing: 0.7px;
        position: relative;
        z-index: 2;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        color: #555;
        letter-spacing: 0.88px;
        line-height: 26px;
        position: relative;
        z-index: 2;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
      }
      &:hover {
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          background-color: #28a745;
          -webkit-transition: all 0.3s ease 0s;
          -moz-transition: all 0.3s ease 0s;
          -o-transition: all 0.3s ease 0s;
          transition: all 0.3s ease 0s;
          opacity: 1;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
        }
        .features-title {
          color: #fff;
        }
        p {
          color: #fff;
        }
      }
      @media (max-width: 991px) {
        max-width: 525px;
        margin: {
          left: auto;
          right: auto;
        }
      }
    }
    @media (max-width: 1440px) {
      h2.title {
        font-size: clamp(1.2rem, 3vw, 1.6rem);
      }
      .mini-title {
        font-size: 1rem;
        text-align: center;
      }
      .features-small-item {
        min-height: 255px;
        padding: 15px;
      }
      padding: 0px;
    }
  }
  .section-5 {
    min-height: 215px;
    overflow: hidden;
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: #f2f4f6;
    background: url('~@/assets/images/background/3.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    -webkit-box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.9);
    box-shadow: inset 0 0 0 2000px rgba(31, 35, 60, 0.9);
    .mini-title,
    h2.title {
      text-align: center;
      max-width: 900px;
      width: 80%;
      margin: {
        left: auto;
        right: auto;
      }
      text-transform: capitalize;
    }
    h2.title {
      font-size: clamp(1.2rem, 3vw, 1.8rem);
      font-weight: 600;
      line-height: 120%;
      margin-bottom: 15px;
      color: #fff;
    }
    .mini-title {
      font-size: 1.125rem;
      font-weight: 500;
      text-transform: capitalize;
      text-align: justify;
      text-align-last: center;
      line-height: 120%;
      margin-bottom: 50px;
      color: #fff;
      font-style: italic;
    }

    .achievment-box {
      border-radius: 16px;
      box-sizing: border-box;
      position: relative;
      background-color: #fff;
      padding: 30px 30px 30px;
      height: 320px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin: 12px auto;
      max-width: 75%;
      min-width: 275px;
      &:hover {
        filter: drop-shadow(0 4px 16px rgba(0, 0, 0, 0.08));
      }
      .icon {
        width: 50px;
        height: 50px;
        margin: 10px auto 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      h2 {
        color: #009750;
        font-size: 21px;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 15px;
        text-align: center;
      }
      p {
        text-align: center;
        margin-top: 15px;
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        line-height: 140%;
        color: #333;
      }
      a {
        text-align: center;
        margin-top: 15px;
        color: #333;
        border-bottom: 1px solid #009750;
        text-decoration: none !important;
        span {
          color: #009750;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          text-transform: capitalize;
          line-height: 140%;
        }
        svg,
        i {
          font-size: 1rem;
          color: #009750;
          margin: auto 5px;
        }
      }
      @media (max-width: 991px) {
        min-height: 300px;
        height: auto;
        max-width: 575px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    @media (max-width: 1440px) {
      h2.title {
        font-size: clamp(1.2rem, 3vw, 1.6rem);
      }
      .mini-title {
        font-size: 1rem;
        text-align: center;
        margin-bottom: 15px;
      }
      .achievment-box {
        padding: 15px;
        height: auto;
        min-height: 270px;
      }
      padding: 25px 0;
    }
  }
  .section-6 {
    padding: 50px 0;
    .banner-box {
      @media (min-width: 768px) {
        min-height: 468px;
        padding: 0 64px;
      }
      min-height: 375px;
      padding: 20px;
      background: #000;
      color: #fff;
      align-items: center;
      border-radius: 16px;
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
      display: flex;
      overflow: hidden;
      padding: 16px;
      position: relative;
      box-sizing: border-box;
      .bg-img {
        img {
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          height: 100%;
          max-width: 860px;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 100;
          right: 0;
          object-fit: cover;
        }
      }
      .bg-color {
        background: linear-gradient(46deg, black 35%, rgba(0, 0, 0, 0) 48%);
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 200;
      }
      .text {
        width: 100%;
        max-width: fit-content;
        position: relative;
        z-index: 300;
        h2.title {
          font-size: 2.725rem;
          font-weight: 400;
          line-height: 3.0875rem;
          margin-bottom: 24px;
          text-align: initial;
        }
        .detail {
          font-size: 1.125rem;
          text-align: initial;
          margin-bottom: 0;
        }
        .button-section {
          display: flex;
          flex-direction: column;
          .button {
            letter-spacing: 0.05rem;
            position: relative;
            color: #fff !important;
            min-width: 150px;
            overflow: hidden;
            transition: 0.3s ease-in-out;
            border-radius: 0.3rem;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: 10px;
            max-width: 300px;
            &:hover,
            &:focus,
            &:focus-visible {
              box-shadow: 0 5px 18px rgba(0, 0, 0, 0.2), 0 10px 12px rgba(0, 0, 0, 0.2);
            }
            &.btn-register {
              background: #009750;
              padding: 15px 10px;
              min-height: 55px;
              border-radius: calc(55px * 1.5);
            }
          }
        }
      }
      @media (max-width: 991px) {
        .bg-color {
          background: linear-gradient(26deg, #000000 10%, rgba(0, 0, 0, 0) 60%);
        }
      }
    }
    @media (max-width: 1440px) {
      padding: 20px 0;
      .banner-box {
        min-height: 285px;
        max-width: 992px;
        margin: auto;
        .text {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          h2.title {
            font-size: clamp(1.2rem, 3vw, 1.6rem);
            margin-bottom: 5px;
          }
          .detail {
            font-size: 1rem;
            margin-bottom: 0 !important;
          }
          .button-section {
            .btn-register {
              margin: 5px 0 10px;
            }
          }
        }
      }
    }
  }
}
</style>
